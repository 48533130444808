import SidebarCard from '../SidebarCard';
import EventBus from '../../util/EventBus';
import GoogleIcon from '../../../public/icons/google.svg';
import { HtmlButton } from '../HtmlButton';
import { useProfile } from '../hoc/withProfile';
import { useSuggestions } from '../hoc/withSuggestions';

function ConnectServicesCard() {
	const { config } = useProfile();
	const { access } = useSuggestions({ company: false });

	const showCard =
		config?.shoutoutInputPermissionHidden && access?.needAccessGoogle;

	return !showCard ? null : (
		<SidebarCard
			className="simple connect-services-card"
			title="Stay Connected"
			subtitle="Connecting your calendar helps Swanvo give you the best people suggestions."
		>
			{access?.needAccessGoogle && (
				<HtmlButton
					color="secondary"
					type="filled"
					onClick={() =>
						EventBus.dispatch(EventBus.Event.ACCESS_GIVEN, {
							service: 'google',
						})
					}
				>
					<GoogleIcon style={{ marginRight: 20 }} /> Connect with Google
				</HtmlButton>
			)}
		</SidebarCard>
	);
}

export default ConnectServicesCard;
