import { useState } from 'react';
import ShareLinkDialog from '../ShareLinkDialog';
import Links from '../../../navigation/Links';
import { Share2 } from 'react-feather';

export const ACHIEVEMENT_BADGE_MIN_WIDTH = 210;

export default function AchievementBadge({ user, badge, detailed, shareable }) {
	const [shareDialog, setShareDialog] = useState(false);

	return (
		<>
			<div
				className={`achievement-badge ${detailed ? 'detailed' : ''} ${
					shareable ? 'shareable' : ''
				}`}
				onClick={shareable ? () => setShareDialog(true) : null}
			>
				{typeof badge.image === 'string' ? (
					<img src={badge.image} alt="swanvo achievement badge" />
				) : (
					badge.image()
				)}
				{detailed && (
					<span
						className="achievement-badge-description body-small semibold"
						style={{ marginTop: 10 }}
					>
						{badge.description}
					</span>
				)}

				{shareable && (
					<div className="achievement-badge-share-icon">
						<Share2 />
					</div>
				)}
			</div>

			<ShareLinkDialog
				title="Share Your Achievement"
				message="I've unlocked this badge"
				link={Links.UserAchievements(user?.username, badge.id)}
				visible={shareDialog}
				hide={() => setShareDialog(false)}
			/>
		</>
	);
}
