import SidebarCard from '../../SidebarCard';
import AchievementType from '../../../data/achievements/AchievementType';
import AchievementBadge from './AchievementBadge';
import Button from '../../Button';
import Links from '../../../navigation/Links';
import Util from '../../../util';

export default function AchievementsCard({ user, achievements, authUser }) {
	const shoutoutAchievements = Util.sortAchievements(
		achievements && achievements[AchievementType.SHOUTOUT]
	).filter((badge) => !badge.id.includes('given'));

	const renderTitle = () => (
		<div className="valigned justify-content-between">
			<span>
				{user.id === authUser?.id ? 'My ' : ' '}Badges{' '}
				<em className="semibold" style={{ fontSize: '0.875rem' }}>
					(Beta)
				</em>
			</span>

			<Button
				type="text"
				color="secondary"
				className="extra-bold"
				size="small"
				href={Links.UserAchievements(user.username)}
			>
				View All
			</Button>
		</div>
	);

	return !shoutoutAchievements?.length ? null : (
		<SidebarCard
			className="simple achievements-card"
			title={renderTitle()}
			subtitle="Share these badges on your social media profiles"
		>
			<div className="achievement-badges">
				{shoutoutAchievements.map((badge, index) => (
					<AchievementBadge
						badge={badge}
						key={index}
						shareable={authUser?.id === user.id}
						user={user}
					/>
				))}
			</div>
		</SidebarCard>
	);
}
