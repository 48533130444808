import { useUser } from '../hoc/withUser';
import { useStrongLinks } from '../hoc/withStrongLinks';
import StrongLinksCard from './strong-links/StrongLinksCard';
import { useProfile } from '../hoc/withProfile';
import BreakingBreadDailyCard from './breaking-bread/BreakingBreadDailyCard';
import { useBreakingBreadQuestion } from '../hoc/withBreakingBreadQuestion';
import Config from '../../data/Config';
import ConnectServicesCard from './ConnectServicesCard';

function UserSidebarRight() {
	const { isLoggedIn } = useUser();
	const { stats: authStats } = useProfile();
	const { strongLinks, fetchingStrongLinks } = useStrongLinks();
	const { question, fetchingQuestion } = useBreakingBreadQuestion();

	const skeleton = authStats && !authStats.shoutoutsGiven;

	if (!isLoggedIn) return null;
	return (
		<div className="sidebar-cards right">
			{Config.DAILY_QUESTION_ENABLED && (fetchingQuestion || question) && (
				<>
					<BreakingBreadDailyCard
						question={question}
						fetchingQuestion={fetchingQuestion}
						skeleton={skeleton}
					/>
					<div className="divider" />
				</>
			)}
			{Config.CONNECT_SERVICES_CARD_ENABLED && <ConnectServicesCard />}
			{Config.STRONG_LINKS_ENABLED && (
				<StrongLinksCard
					strongLinks={strongLinks}
					fetchingStrongLinks={fetchingStrongLinks}
					skeleton={skeleton}
				/>
			)}
		</div>
	);
}

export default UserSidebarRight;
