import { Carousel } from 'antd';
import Avatar from '../../Avatar';
import StrongLinkConnection from '../../../../public/images/strong-link-connection.svg';

function StrongLinkSuccessView({ user, strongLink }) {
	return (
		<div className="strong-links-success-view">
			<div className="strong-links-success-users">
				<Avatar size={80} src={user.image} name={user.name || user.username} />
				<div className="strong-link-connection">
					<StrongLinkConnection />
				</div>
				<Avatar
					size={80}
					src={strongLink.image}
					name={strongLink.name || strongLink.username}
				/>
			</div>

			<p className="strong-links-success-message body">
				You and {strongLink.name || strongLink.username} have anonymously
				recognised each other as a strong link!
			</p>
		</div>
	);
}

export default function StrongLinksSuccess({ user, strongLinks }) {
	return (
		<div className="strong-links-success" style={{ marginTop: 20 }}>
			<Carousel
				draggable
				dotPosition="bottom"
				dots={strongLinks?.length > 1}
				autoplay={false}
				initialSlide={0}
			>
				{strongLinks?.map((strongLink, index) => (
					<StrongLinkSuccessView
						key={index}
						user={user}
						strongLink={strongLink}
					/>
				))}
			</Carousel>
		</div>
	);
}
