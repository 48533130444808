import useSWR from 'swr';
import UserService from '../../services/UserService';
import Config from '../../data/Config';
import { useUser } from './withUser';
import Constants from '../../util/Constants';

export function useStrongLinks() {
	if (!Config.STRONG_LINKS_ENABLED) return {};

	const { user } = useUser();
	const { data: response, error, mutate } = useSWR(
		user ? UserService.getStrongLinks(true) : null,
		undefined,
		Constants.SWR_CONFIG
	);

	async function addStrongLinks(links) {
		if (!links?.length) return;

		const newEmails = links.map((l) => l.email);
		await mutate({
			strongLinks: response?.strongLinks?.length
				? response.strongLinks.concat(links)
				: links,
			strongLinksPending: response?.strongLinksPending?.length
				? response.strongLinksPending.filter(
						(s) => !newEmails.includes(s.email)
				  )
				: response?.strongLinksPending,
		});
	}

	return {
		strongLinks: response?.strongLinks,
		strongLinksPending: response?.pending,
		strongLinksError: error,
		fetchingStrongLinks: !response && !error,
		addStrongLinks,
	};
}

export function withStrongLinks(WrappedComponent) {
	// eslint-disable-next-line react/display-name
	return (props) => {
		const {
			strongLinks,
			strongLinksError,
			fetchingSuggestions,
			addStrongLinks,
		} = useStrongLinks();
		return (
			<WrappedComponent
				{...props}
				strongLinks={strongLinks}
				fetchingSuggestions={fetchingSuggestions}
				addStrongLinks={addStrongLinks}
				strongLinksError={strongLinksError}
			/>
		);
	};
}
