import { useState } from 'react';
import SidebarCard from '../../SidebarCard';
import { HtmlButton } from '../../HtmlButton';
import Avatar from '../../Avatar';
import StrongLinksSelectDialog from './StrongLinksSelectDialog';
import { Skeleton } from 'antd';

function DummyView({ title }) {
	return (
		<SidebarCard className="simple" title={title}>
			<div style={{ height: 5 }} />
			<Skeleton paragraph={{ rows: 3 }} title={false} />
			<div style={{ height: 10 }} />
			<div className="strong-links-card-avatars" style={{ marginBottom: 30 }}>
				<div className="strong-link-users">
					{[1, 2, 3, 4].map((_, index) => (
						<div className="strong-link-user" key={index}>
							<Avatar size={40} />
						</div>
					))}
				</div>
			</div>
			<Skeleton paragraph={{ rows: '100%' }} title={false} />
		</SidebarCard>
	);
}

function StrongLinksCard({ strongLinks, skeleton }) {
	const [selectDialogVisible, setSelectDialogVisible] = useState(false);
	const length = strongLinks?.filter((s) => !s.dummy)?.length || 0;

	let mStrongLinks = strongLinks;
	if (strongLinks?.length < 4) {
		mStrongLinks = mStrongLinks || [];
		for (let i = 0; i < 4 - length; i++) {
			mStrongLinks.push({ dummy: true });
		}
	}
	const title = 'Your Strong Links';

	if (skeleton) return <DummyView title={title} />;
	return (
		<SidebarCard
			className="simple"
			title={title}
			subtitle="Select the people you love working with and if they select you back they appear here."
		>
			<div className="strong-links-card-avatars" style={{ marginBottom: 30 }}>
				<p
					className="semibold"
					style={{ color: 'var(--text-color-light)', marginBottom: 10 }}
				>
					Links ({length})
				</p>
				<div className="strong-link-users">
					{mStrongLinks?.map((user, index) => (
						<div
							className={`strong-link-user ${!user.dummy ? 'active' : ''}`}
							key={index}
						>
							<Avatar
								src={user.image}
								name={user.name || user.email}
								size={40}
							/>
							<p className="strong-link-name">
								{user.name?.split(' ')[0] || user.email}
							</p>
						</div>
					))}
				</div>
			</div>

			<HtmlButton
				type="minimal"
				color="secondary"
				className="w-100 extra-bold"
				size="small"
				onClick={() => setSelectDialogVisible(true)}
			>
				{length > 0 ? 'EXPAND YOUR LINKS' : 'BEGIN SELECTION'}
			</HtmlButton>

			<StrongLinksSelectDialog
				visible={selectDialogVisible}
				hide={() => setSelectDialogVisible(false)}
			/>
		</SidebarCard>
	);
}

export default StrongLinksCard;
