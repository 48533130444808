import { HtmlButton } from '../HtmlButton';
import Util from '../../util';
import Notification from '../../util/Notification';
import Analytics from '../../data/Analytics';
import Dialog from '../Dialog';
import Constants from '../../util/Constants';
import FacebookLogo from '../../../public/icons/facebook.svg';
import LinkedInLogo from '../../../public/icons/linkedin.svg';

export default function ShareLinkDialog({
	visible,
	hide,
	title,
	message,
	link,
}) {
	link = `https://${Constants.WEB_LINK.toLowerCase()}${link}`;

	const copyToClipboard = () => {
		Util.copyToClipboard(link);
		Notification.info('Copied to clipboard', { duration: 1 });

		Analytics.event(Analytics.Event.SHARE_LINK, {
			event: 'copy',
			place: 'dialog',
			link: link,
		});
	};

	const onFacebookShare = () => {
		window.FB?.ui(
			{
				quote: message,
				method: 'share',
				href: link,
			},
			() => {}
		);
	};

	const onLinkedInShare = () => {
		window.open(
			`https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
			'_blank',
			'location=yes,height=570,width=520,titlebar=no,toolbar=no,status=yes'
		);
	};

	return (
		<Dialog
			className="share-link-dialog"
			title={title}
			visible={visible}
			hide={hide}
		>
			<div className="social-share-buttons">
				<HtmlButton color="grey" type="outlined" onClick={onFacebookShare}>
					<FacebookLogo /> Facebook
				</HtmlButton>
				<HtmlButton color="grey" type="outlined" onClick={onLinkedInShare}>
					<LinkedInLogo /> LinkedIn
				</HtmlButton>
			</div>

			<div className="share-link">
				<span>{link}</span>
				<HtmlButton color="white" size="small" onClick={copyToClipboard}>
					Copy
				</HtmlButton>
			</div>
		</Dialog>
	);
}
