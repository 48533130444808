import { useState } from 'react';
import Shoutouts from '../modules/shoutout/Shoutouts';
import UserSidebarCard from '../modules/UserSidebarLeft';
import ShoutoutInputCard from '../modules/shoutout/ShoutoutInputCard';
import Page from '../Page';
import { withRouter } from 'next/router';
import UserSidebarRight from '../modules/UserSidebarRight';
import { useSuggestions } from '../hoc/withSuggestions';
import { useUser } from '../hoc/withUser';
import LockedShoutoutCard from '../modules/shoutout/LockedShoutoutCard';
import { Select } from 'antd';

function UserPage({ user, shoutouts, stats, meta, ...props }) {
	const {
		suggestions,
		fetchingSuggestions,
		access: suggestionAccess,
	} = useSuggestions();
	const { user: authUser } = useUser();
	const [activeTab, setActiveTab] = useState(0);
	const hiddenCount = shoutouts.hidden?.count || 0;

	const onShoutoutSubmitted = () => {
		(hiddenCount > 0 || !shoutouts.given?.length) && props.router.reload();
	};

	const renderHeader = () => (
		<div className="options-header">
			<h6 className="title">
				{activeTab === 0 ? 'Received Shoutouts' : 'Given Shoutouts'}
			</h6>
			<div className="divider" />
			<Select
				value={activeTab}
				dropdownClassName="select-dialog"
				className="select-dialog"
				onChange={(type) => setActiveTab(type)}
			>
				<Select.Option value={0} key="1">
					Received
				</Select.Option>
				<Select.Option value={1} key="2">
					Given
				</Select.Option>
			</Select>
		</div>
	);

	return (
		<Page meta={meta} className="user-page profile-page">
			<UserSidebarCard user={user} stats={stats} />
			<div className="profile-body">
				<ShoutoutInputCard
					showSuggestions
					suggestionAccess={suggestionAccess}
					suggestions={suggestions}
					onSubmitted={onShoutoutSubmitted}
					fetchingSuggestions={fetchingSuggestions}
				/>

				{renderHeader()}

				{authUser?.id === user.id && hiddenCount > 0 && (
					<LockedShoutoutCard count={hiddenCount} />
				)}
				<Shoutouts
					user={authUser}
					shoutouts={activeTab === 0 ? shoutouts.received : shoutouts.given}
				/>
			</div>
			<UserSidebarRight stats={stats} />
		</Page>
	);
}

export default withRouter(UserPage);
