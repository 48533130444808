import Avatar from '../Avatar';
import SidebarCard from '../SidebarCard';
import ShareLinkCard from './ShareLinkCard';
import Constants from '../../util/Constants';
import Links from '../../navigation/Links';
import { useUser } from '../hoc/withUser';
import Link from '../Link';
import { ShoutoutRankCard } from '../RankCard';
import AchievementsCard from './achievements/AchievementsCard';
import Config from '../../data/Config';
import { useRouter } from 'next/router';

function UserSidebarLeft({ user, stats, showAchievements = true }) {
	const { user: authUser } = useUser();
	const router = useRouter();

	return (
		<div className="sidebar-cards left">
			<SidebarCard className="profile-sidebar-card">
				<div className="profile-card">
					<Avatar size={60} name={user.name || user.email} src={user.image} />
					<div className="profile-card-info">
						<p className="profile-card-title">{user.name || user.username}</p>
						{Constants.DEBUG && <div className="body-xsmall">{user.id}</div>}
						{user.company && (
							<Link
								href={Links.Company(user.company)}
								className="profile-card-subtitle hover-underline"
							>
								{authUser?.id === user.id ? 'See Company Board' : user.company}
							</Link>
						)}
					</div>
				</div>
			</SidebarCard>
			{stats?.rank && (
				<>
					<SidebarCard className="simple">
						<ShoutoutRankCard
							rank={stats.rank}
							count={stats.shoutoutsReceived}
							onClick={() => router.push(Links.User(user.username))}
						/>
					</SidebarCard>
				</>
			)}
			{authUser?.id === user.id && Config.SHARE_LINK_CARD_ENABLED && (
				<>
					<div className="divider" />
					<ShareLinkCard
						message="Share this link with others to build your profile"
						link={`${Constants.WEB_LINK}${Links.User(
							authUser.username || user.username
						)}`}
					/>
				</>
			)}

			{showAchievements && Config.ACHIEVEMENTS_ENABLED && stats?.achievements && (
				<>
					<div className="divider" />
					<AchievementsCard
						user={user}
						authUser={authUser}
						achievements={stats?.achievements}
					/>
				</>
			)}
		</div>
	);
}

export default UserSidebarLeft;
