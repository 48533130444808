import { useEffect, useState } from 'react';
import Dialog from '../../Dialog';
import { useStrongLinks } from '../../hoc/withStrongLinks';
import UserService from '../../../services/UserService';
import Notification from '../../../util/Notification';
import UserSuggestionsDialogView from '../user/suggestions/UserSuggestionsDialogView';
import Analytics from '../../../data/Analytics';
import { useUser } from '../../hoc/withUser';
import StrongLinksSuccess from './StrongLinksSuccess';

function StrongLinksDialogView({ hide, onStrongLinksAdded }) {
	const { strongLinks, strongLinksPending, addStrongLinks } = useStrongLinks();
	const [submitting, setSubmitting] = useState(false);
	const { user } = useUser();

	useEffect(() => {
		Analytics.event(Analytics.Event.STRONG_LINKS, { event: 'open_dialog' });
	}, []);

	// Filtering strong links from suggestions
	const strongLinkEmails = strongLinks
		?.map((s) => s.email)
		?.concat(strongLinksPending ? strongLinksPending.map((s) => s.email) : [])
		?.filter(Boolean);

	const onSubmit = (selected) => {
		setSubmitting(true);
		UserService.requestStrongLinks(selected.map((u) => u.email).filter(Boolean))
			.then((result) => {
				addStrongLinks(result.added);
				Analytics.event(Analytics.Event.STRONG_LINKS, { event: 'add' });
				Notification.success('Anonymous recognition done!');

				onStrongLinksAdded && onStrongLinksAdded(result.added);
			})
			.catch(() =>
				Notification.error('Failed to submit your request. Please try again.')
			)
			.finally(() => setSubmitting(false));
	};

	const onCustomEmailExisting = (user) => {
		Notification.warn(
			`${user.name || user.email} is already in your strong list.`
		);
	};

	return (
		<UserSuggestionsDialogView
			cardUI
			calendar={!user?.company}
			selectionMode={true}
			hide={submitting ? undefined : hide}
			onSubmit={onSubmit}
			filterSuggestions={(suggestions) =>
				suggestions?.filter(
					(s) => !strongLinkEmails || !strongLinkEmails.includes(s.email)
				)
			}
			preSelected={10}
			maxSuggestions={0}
			loading={submitting}
			submitButtonText="Done"
			onCustomEmailExisting={onCustomEmailExisting}
		/>
	);
}

function StrongLinksDialog({ visible, hide }) {
	const { user } = useUser();
	const [addedLinks, setLinks] = useState(null);

	const onStrongLinksAdded = (links) => {
		hide();

		if (links) setLinks(links.filter((f) => f.email || f.username));
	};

	return (
		<>
			<Dialog
				visible={visible}
				hide={hide}
				size="large"
				title="Select People You Love Working With"
				className="no-body-padding"
			>
				<div className="dialog-subtitle" style={{ marginBottom: 20 }}>
					Anonymously recognise people, and if they recognise you back, you
					become each others strong link.
				</div>
				<StrongLinksDialogView
					hide={hide}
					onStrongLinksAdded={onStrongLinksAdded}
				/>
			</Dialog>

			<Dialog
				title="Your Strong Links"
				visible={addedLinks?.length}
				size="small"
				className="centered-title"
				hide={() => setLinks(null)}
			>
				<StrongLinksSuccess strongLinks={addedLinks} user={user} />
			</Dialog>
		</>
	);
}

export default StrongLinksDialog;
